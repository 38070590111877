import { Modal, Button, Select, TextInput } from '@mantine/core';
import { Body, Footer, FormLayout } from './styles';
import { EntryItemType, EntryType } from '@morph-mapper/types';
import { getEntries, isNotUndefined } from '@morph-mapper/utils';
import { match } from 'ts-pattern';
import { ChangeEvent, useState } from 'react';
import {
  StructureBlockIndex,
  useStructureBlocks,
} from '@morph-mapper/node-logic';
import { useStore } from '@/store';
import { useNotify } from '@/hooks';
import _ from 'lodash';
import { useTreeContext } from '../../context';
import { t } from 'i18next';

interface CreateEntryModalProps {
  opened: boolean;
  close: () => void;
}

export const CreateEntryModal = ({ opened, close }: CreateEntryModalProps) => {
  const [variant, createMap, createItem, linkChild] = useStore(
    ({ config: c, entries: e }) => [
      c.getVariant(),
      e.createMap,
      e.createItem,
      e.linkChild,
    ]
  );
  const parentId = useTreeContext((s) => s.getCurrentId());
  const { structureBlocksByCategory } = useStructureBlocks(variant);
  const [type, setType] = useState<EntryType | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [outputType, setOutputType] = useState<StructureBlockIndex | undefined>(
    undefined
  );
  const { warn } = useNotify();

  const getEntryTypes = () =>
    getEntries(EntryType)
      .map(([title, type]) =>
        match(type)
          .with(EntryType.Internal, () => {
            return undefined;
          })
          .otherwise(() => {
            return {
              value: type,
              label: title,
            };
          })
      )
      .filter(isNotUndefined);

  const getEntryMapTypes = () =>
    getEntries(structureBlocksByCategory)
      .map(([category, structureBlocks]) =>
        structureBlocks.map(({ title, type }) => ({
          value: type,
          label: title,
          group: category,
        }))
      )
      .flat();

  const handleType = (value: EntryItemType) => {
    setType(value);
  };

  const handleName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleOutputType = (value: StructureBlockIndex) => {
    setOutputType(value);
  };

  const handleCreate = () => {
    if (type === undefined) {
      warn(t('message.warning.selectType'));
      return;
    }
    if (name === undefined || name === '') {
      warn(t('message.warning.enterName'));
      return;
    }

    const id = match(type)
      .with(EntryType.Map, () => {
        if (outputType === undefined) {
          warn(t('message.warning.selectMapType'));
          return;
        }
        // TODO: add schema options?
        return createMap({
          outputType,
          name,
          key: _.camelCase(name),
          parentId,
        });
      })
      .otherwise((type) => {
        // TODO: add validation
        return createItem({ name, key: _.camelCase(name), parentId }, type);
      });

    if (id) {
      linkChild(parentId, id);
    }

    close();
  };

  return (
    <Modal title={t('modal.createEntry.title')} opened={opened} onClose={close} centered>
      <Body>
        <FormLayout>
          <Select
            label={t('modal.createEntry.form.type.label')}
            description={t('modal.createEntry.form.type.description')}
            data={getEntryTypes()}
            onChange={handleType}
            value={type}
            withinPortal
          />
          {match(type)
            .with(undefined, () => null)
            .with(EntryType.Map, () => {
              return (
                <>
                  <TextInput
                   label={t('modal.createEntry.form.name.label')}
                   description={t('modal.createEntry.form.name.description')}
                    onChange={handleName}
                    value={name}
                  />
                  <Select
                    label={t('modal.createEntry.form.mapType.label')}
                    description={t('modal.createEntry.form.mapType.description')}
                    data={getEntryMapTypes()}
                    onChange={handleOutputType}
                    value={outputType}
                    withinPortal
                  />
                </>
              );
            })
            .otherwise(() => {
              return (
                <TextInput
                label={t('modal.createEntry.form.name.label')}
                description={t('modal.createEntry.form.name.description')}
                  onChange={handleName}
                  value={name}
                />
              );
            })}
        </FormLayout>
        <Footer>
          <Button color="blue" onClick={handleCreate}>
            {t('button.create')}
          </Button>
        </Footer>
      </Body>
    </Modal>
  );
};
