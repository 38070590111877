import { StoreSlice } from '@/store';
import { createStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface TreeProps {
  pointer: string[];
  copySourceId: string | null;
}

type InitTreeProps = { id: string };

export interface TreeState extends TreeProps {
  moveIn: (reference: string) => void;
  moveOut: () => void;

  getCurrentId: () => string;
  getChildrenIds: () => string[];

  copyEntry: (sourceId: string) => void;
  pasteEntry: (targetId: string) => void;
  hasCopiedEntry: () => boolean;
}

export type TreeStore = ReturnType<typeof createTreeStore>;

export const createTreeStore = (
  { entries }: StoreSlice,
  { id }: InitTreeProps
) => {
  return createStore<TreeState>()(
    persist(
      immer((set, get) => ({
        pointer: [id],
        copySourceId: null,

        moveIn: (nextFocus) =>
          set((s) => {
            s.pointer.push(nextFocus);
          }),
        moveOut: () =>
          set((s) => {
            s.pointer.pop();
          }),

        getCurrentId: () => {
          const currentId = get().pointer[get().pointer.length - 1];
          if (currentId === undefined) {
            throw new Error('Pointer is empty.');
          }

          return currentId;
        },

        getChildrenIds: () => {
          const currentId = get().pointer[get().pointer.length - 1];
          if (currentId === undefined) {
            throw new Error('Pointer is empty.');
          }

          return entries.children(currentId).getIds();
        },

        copyEntry: (targetId) => {
          set((s) => {
            s.copySourceId = targetId;
          });
        },
        pasteEntry: (targetId) => {
          set((s) => {
            if (s.copySourceId === null) {
              throw new Error('Copy target is not set.');
            }

            entries.copy(s.copySourceId, targetId);

            s.copySourceId = null;
          });
        },
        hasCopiedEntry: () => get().copySourceId !== null,
      })),
      {
        name: 'entry-editor-tree',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  );
};
