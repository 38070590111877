import { useStore } from '@/store';
import { SchemaConfig, useSchema } from '@morph-mapper/schemas';
import { getEntries, getKeys } from '@morph-mapper/utils';
import { t } from 'i18next';
import { useEffect } from 'react';

export const useSchemaConfig = () => {
  const [
    setValue,
    setIsDocModalOpen,
    preconfig,
    updatePreConfig,
    domain,
    type,
    variant,
    definedCustomSchemes,
  ] = useStore(({ entries: e, config: c }) => [
    e.setTemplateValue,
    c.setIsDocModalOpen,
    c.getPreconfiguration(),
    c.updatePreconfiguration,
    c.getDomain(),
    c.getType(),
    c.getVariant(),
    c.definedCustomSchemes,
  ]);
  const { getSchema } = useSchema(domain, definedCustomSchemes);
  const { config } = getSchema(type, variant);

  useEffect(() => {
    setDefaultIfUndefined();
  }, []);

  const setDefaultIfUndefined = <T extends keyof SchemaConfig>() => {
    if (!config) {
      throw 'No configuration available';
    }

    const configurations = getIdsForConfiguration()
      .map((id) => [id, getKeyById(id)])
      .map<[string, SchemaConfig[T]]>(([id, key]) => [
        id,
        getConfigurationByKey(key),
      ]);

    const batchUpdates: Record<string, any> = {};

    configurations.forEach(([id, config]) => {
      const newOptions = { ...preconfig[id]?.options };

      (getKeys(config.options) as string[]).forEach((optionKey) => {
        const option = config.options[optionKey];
        const isDefined = getConfigValue(id, optionKey) !== undefined;

        if (!isDefined) {
          newOptions[optionKey] = option.default;
        }
      });

      batchUpdates[id] = {
        key: getKeyById(id),
        options: newOptions,
      };
    });

    // Batch updates for each configuration ID to avoid race conditions
    getKeys(batchUpdates).forEach((id) => {
      const { key, options } = batchUpdates[id];
      updatePreConfig(id, { key, options });

      setValue(id, config[key].mutate(options));
    });
  };

  const getIdsForConfiguration = () => {
    return Object.keys(preconfig);
  };

  const getKeyById = (id: string) => {
    return preconfig[id].key;
  };

  const getConfigValue = (id: string, optionKey: string) => {
    return preconfig[id].options[optionKey];
  };

  const getOptionValue = (id: string, optionKey: string): any => {
    const config = preconfig[id];
    if (!config) {
      throw `Configuration with id '${id}' not found`;
    }

    return config.options[optionKey];
  };

  const getDefaultByKey = (id: string, optionKey: string) => {
    if (!config) {
      throw 'No configuration available';
    }

    const configKey = getKeyById(id);
    const optionDefault = config[configKey].options[optionKey].default;

    return optionDefault;
  };

  const updateConfig = (id: string, optionKey: string, value: any) => {
    if (!config) {
      throw 'No configuration available';
    }

    const key = getKeyById(id);
    const newOptions = { ...preconfig[id].options, [optionKey]: value };

    // TODO: we maintain the state in 2 places in the store, but check if we can do it in one place, as the state may become inconsistent.
    // Or, we assume all variables controlled by the configuration are internals and thus we don't need to maintain them in 1 of the 2 places.
    updatePreConfig(id, {
      key,
      options: newOptions,
    });

    setValue(id, config[key].mutate(newOptions));
  };

  const getConfigurationByKey = <T extends keyof SchemaConfig>(
    key: T
  ): SchemaConfig[T] => {
    if (!config) {
      throw 'No configuration available';
    }

    return config[key];
  };

  const getOptionByKey = (configId: string, optionKey: string) => {
    if (!config) {
      throw 'No configuration available';
    }

    const configKey = getKeyById(configId);
    const options = config[configKey].options[optionKey];

    return options;
  };

  const hasConfiguration = () => {
    return Object.keys(preconfig).length !== 0;
  };

  const hasFilledRequiredOptions = () => {
    if (!config) {
      throw 'No configuration available';
    }

    let inValid = false;

    getEntries(preconfig).forEach(([id, { key, options }]) => {
      getKeys(config[key].options).forEach((optionKey) => {
        const isRequired =
          config[key].options[optionKey].options?.required ?? false;
        const isDefined = options[optionKey] !== undefined;

        if (isRequired && !isDefined) {
          inValid = true;
        }
      });
    });

    return inValid;
  };

  const translateConfiguration = (
    configurations: SchemaConfig
  ): SchemaConfig => {
    return Object.entries(configurations).reduce((acc, [key, config]) => {
      const titleKey = `configurations.${key}.title`;
      const descriptionKey = `configurations.${key}.description`;

      const translatedConfig = {
        ...config,
        title: t(titleKey, config.title),
        description: t(descriptionKey, config.description || ''),
      };

      return {
        ...acc,
        [key]: translatedConfig,
      };
    }, {} as SchemaConfig);
  };

  return {
    getIdsForConfiguration,
    getKeyById,
    getConfigValue,
    updateConfig,
    hasConfiguration,
    getConfigurationByKey,
    hasFilledRequiredOptions,
    setIsDocModalOpen,
    getOptionByKey,
    getOptionValue,
    getDefaultByKey,
    translateConfiguration,
  };
};
