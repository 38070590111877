import { Box, Button, Popover, TextInput } from '@mantine/core';
import {
  useCategoriesOfOperatorIdByDomain,
  useCreateCategory,
} from '@/services';
import { useEffect, useState } from 'react';
import { useSetupStore } from '../../store';
import { useNotify } from '@/hooks';
import { t } from 'i18next';

export const CategoryPopover = () => {
  const [domain, operatorId] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
  ]);
  const { data: categories } = useCategoriesOfOperatorIdByDomain(
    operatorId,
    domain
  );
  const [opened, setOpened] = useState(false);
  const [category, setCategory] = useState('');
  const [createCategoryDisabled, setCreateCategoryDisabled] = useState(false);
  const [error, setError] = useState('');
  const { mutate: addCategory, isSuccess } = useCreateCategory();
  const { success } = useNotify();

  useEffect(() => {
    if (!isSuccess) return;

    success(t('message.success.categoryCreated'));
    setCategory('');
  }, [isSuccess]);

  // Handle error messages
  useEffect(() => {
    if (category === '') {
      setCreateCategoryDisabled(true);
    } else if (hasNameConflict()) {
      setError('Category name already exists');
      setCreateCategoryDisabled(true);
    } else {
      setCreateCategoryDisabled(false);
      setError('');
    }
  }, [category]);

  if (categories === undefined) return null;

  const handleCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value);
  };

  const hasNameConflict = () => {
    return (
      categories.some(({ name }) => name === category) || category === 'default'
    );
  };

  const createCategory = () => {
    addCategory({ operatorId, name: category, domain });
    setOpened(false);
  };

  const handleOpen = () => {
    setOpened(true);
  };

  return (
    <Popover
      position="right-start"
      offset={10}
      trapFocus
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Button variant="default" color="gray" onClick={handleOpen}>
          {t('button.addCategory')}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ marginBottom: '1rem' }}>
            <TextInput
              label={t('common.name')}
              size="xs"
              value={category}
              onChange={handleCategory}
              error={error}
            />
          </Box>

          <Button onClick={createCategory} disabled={createCategoryDisabled}>
            {t('button.create')}
          </Button>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
