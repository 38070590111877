import { getKeys } from '@morph-mapper/utils';
import { useMemo } from 'react';
import { useStore } from '@/store';
import { useConnectors, useLogicBlocks } from '@morph-mapper/node-logic';
import { Edges, Nodes } from '../../molecules';

export const useGraphView = () => {
  /* Hooks */
  const variant = useStore(({ config: c }) => c.getVariant());
  const { logicBlocks } = useLogicBlocks(variant);
  const { connectors } = useConnectors();

  /*
   * Initialize node and edge types from configuration constants.
   */
  const nodeTypes = useMemo(
    () =>
      getKeys(logicBlocks).reduce((acc, key) => {
        return {
          ...acc,
          [key]: Nodes[logicBlocks[key].node](),
        };
      }, {}),
    [logicBlocks]
  );
  const edgeTypes = useMemo(
    () =>
      getKeys(connectors).reduce((acc, key) => {
        return {
          ...acc,
          [key]: Edges[connectors[key].edge](),
        };
      }, {}),
    []
  );

  return { nodeTypes, edgeTypes };
};
