import { Stepper as MantineStepper } from '@mantine/core';
import { t } from 'i18next';


interface StepperProps {
  step: number;
}

export const Stepper = ({ step }: StepperProps) => {
  // Adjust the active step
  let activeStep = step;
  if (step === 3 || step === 4) {
    activeStep--;
  }

  return (
    <MantineStepper active={activeStep} orientation="vertical" size="lg">
      <MantineStepper.Step
        label={t('stepper.steps.domain.label')}
        description={t('stepper.steps.domain.description')}
        h={150}
      />
      <MantineStepper.Step
        label={t('stepper.steps.template.label')}
        description={t('stepper.steps.template.description')}
        h={150}
      />
      <MantineStepper.Step
        label={t('stepper.steps.file.label')}
        description={t('stepper.steps.file.description')}
        h={150}
      />
      <MantineStepper.Step
        label={t('stepper.steps.build.label')}
        description={t('stepper.steps.build.description')}
        h={150}
      />
    </MantineStepper>
  );
};
