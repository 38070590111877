import { useStore } from '@/store';
import { useNavigate } from 'react-router-dom';
import { useNotify, useSchemaConfig } from '@/hooks';
import { useFileToTextParser } from '@/services';
import { useEffect, useState } from 'react';
import { ACCEPTED_FILE_EXTENSIONS, MIME_TYPE_TO_EXTENSION } from '@/config';
import { t } from 'i18next';

export const useFileSelect = () => {
  /* Global state */
  const [setStoreFile, setTextContent, variant] = useStore(({ config: c }) => [
    c.setFile,
    c.setTextContent,
    c.getVariant(),
  ]);
  /* Local state */
  const [file, setFile] = useState<File | undefined>();
  const [fileLoaded, setFileLoaded] = useState(false);
  const [notification, setNotification] = useState<string | undefined>(
    undefined
  );
  /* Hooks */
  const { hasConfiguration } = useSchemaConfig();
  const navigate = useNavigate();
  const { close, warn, loading } = useNotify();
  /* Queries */
  const { data } = useFileToTextParser(file);

  /**
   * Effects
   */
  useEffect(() => {
    if (file === undefined) {
      return;
    }

    const id = loading(t('message.loading.file'));
    setNotification(id);
  }, [file]);

  useEffect(() => {
    if (data === undefined) {
      return;
    }

    setTextContent(data);
  }, [data]);

  useEffect(() => {
    if (notification === undefined) {
      return;
    }
    if (data === undefined) {
      return;
    }

    close(notification);
    setNotification(undefined);
    setFileLoaded(true);
  }, [data, notification]);

  /**
   * Handlers
   */
  // When the user clicks the next button, set the file in the global state.
  const progressSetup = () => {
    if (file === undefined) {
      warn(t('message.warning.selectFile'));

      return;
    }

    setStoreFile(file);

    // Check if the template uses a configuration,
    // which the user should define before working on the template.
    if (hasConfiguration()) {
      navigate('/configure');
    } else {
      navigate('/entries');
    }
  };

  const getAcceptedFileExtensions = () => {
    return ACCEPTED_FILE_EXTENSIONS[variant];
  };

  const getAcceptedFileExtensionsDisplay = () => {
    const mimeTypes = ACCEPTED_FILE_EXTENSIONS[variant];
    const extensions = mimeTypes.map(
      (mimeType) => MIME_TYPE_TO_EXTENSION[mimeType] || mimeType
    );
    return Array.from(new Set(extensions));
  };

  return {
    file,
    setFile,
    progressSetup,
    disableNext: !fileLoaded,
    getAcceptedFileExtensions,
    getAcceptedFileExtensionsDisplay,
  };
};
