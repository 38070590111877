import z from 'zod';

export const operatorSchema = z.object({
  id: z.string(),
  operatorid: z.string(),
  domains: z.array(z.string()),
  language: z.string().optional(),
  schemes: z.record(  // A record of domains
    z.record(         // For each domain, there can be a record of schema types
      z.record(z.string()) // For each type, variants with custom schemas are defined
    )
  ).optional(),
});

//example:
// {
//   "operatorid": "100",
//   "domains": ["retail"],
//   "schemes": {
//     "retail": {
//       "standard": {
//         "table": "customRetailTableSchema",
//       }
//     }
//   }
// }

export type Operator = z.infer<typeof operatorSchema>;
