import { Stack } from '@mantine/core';
import { ConfigRow } from '../config-row';
import { SectionLayout } from '@/common';
import { OptionConfig, OptionsRecord } from '@morph-mapper/node-logic';

interface ConfigContentProps {
  options: OptionsRecord;
}

interface ConfigContainerProps {
  name: string;
  optionConfig: OptionConfig;
}

export const ConfigContent = ({ options }: ConfigContentProps) => {
  return (
    <Stack>
      <SectionLayout title={'General'} key={'general'}>
        {Object.entries(options).map(([name, optionConfig]) => {
          return (
            <ConfigContainer
              name={name}
              optionConfig={optionConfig}
              key={name}
            />
          );
        })}
      </SectionLayout>
    </Stack>
  );
};

export const ConfigContainer = ({
  name,
  optionConfig,
}: ConfigContainerProps) => {
  const { title, input, description, conditions } = optionConfig;

  return (
    <ConfigRow
      title={title}
      description={description}
      optionName={name}
      conditions={conditions}
      input={input}
      key={title}
    />
  );
};
