import { useSetupStore } from '../../store';
import {
  useUpdateTemplateRef,
  useTemplateSave,
  useCreateTemplateSave,
  useCreateTemplateRef,
  useTemplateRefsOfOperatorId,
  useCreateCategory,
} from '@/services';
import { useEffect, useState } from 'react';
import { useNotify } from '@/hooks';
import { Category } from '../../types';
import { ediOptionInputs, EdiOptions, SchemaSource } from '@morph-mapper/types';
import { t } from 'i18next';
import { Buffer } from 'buffer';


export const useConfigureTemplate = (categories: Category[] | undefined) => {
  const [
    domain,
    operatorId,
    setOpenEdit,
    openDuplicate,
    setDuplicateOpen,
    modalId,
    setModalId,
  ] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
    s.setIsEditModalOpen,
    s.isDuplicateModalOpen,
    s.setIsDuplicateModalOpen,
    s.modalId,
    s.setModalId,
  ]);

  const { success, error, warn} = useNotify();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState(0);
  const [category, setCategory] = useState('');
  const [source, setSource] = useState('');
  const [mainTemplatePath, setMainTemplatePath] = useState<string | null>(null);
  const [mainTemplate, setMainTemplate] = useState<any>(undefined);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [ediVariables, setEdiVariables] = useState<
    | Record<string, { value: string | number; schemaVariable: string }>
    | undefined
  >(undefined);
  const [ediOption, setEdiOption] = useState<EdiOptions | undefined>(undefined);

  /* Queries */
  const { data: templates } = useTemplateRefsOfOperatorId(operatorId);

  const { mutate: addCategory } = useCreateCategory();
  const {
    mutate: createTemplateRef,
    isSuccess: refSuccess,
    data: templateRefId,
  } = useCreateTemplateRef();
  const { mutate: updateTemplate, isSuccess: updateSuccess } =
    useUpdateTemplateRef();
  const { data: duplTemplateFetched } = useTemplateSave(mainTemplatePath, true);
  const {
    mutate: createTemplateSave,
    isSuccess: saveSuccess,
    isError: saveError,
  } = useCreateTemplateSave();

  useEffect(() => {
    if (templates === undefined || modalId === undefined) return;

    const modalTemplate = templates.find((template) => template.id === modalId);
    setMainTemplate(modalTemplate);
  }, [templates, modalId]);

  useEffect(() => {
    if (name === '') return;
    checkNameConflict();
  }, [name, category]);

  useEffect(() => {
    if (mainTemplate === undefined) return;

    // Update modal state with mainTemplate values
    setName(mainTemplate.name);
    setCategory(mainTemplate.category);
    setSource(mainTemplate.inputsource);
    setPriority(mainTemplate.priority);
    setDescription(mainTemplate.description ?? '');
    setMainTemplatePath(mainTemplate.path);
    setEdiOption(mainTemplate.ediType);
    setEdiVariables(mainTemplate.ediVariables);
  }, [mainTemplate]);

  //useEffect for update success for configure
  useEffect(() => {
    if (!updateSuccess) return;

    createCategoryIfNotExists();

    setOpenEdit(false);
    success(t('message.success.templateUpdated'));
    setName('');
    setDescription('');
    setPriority(0);
    setCategory('');
    setModalId(undefined);
    setMainTemplate(undefined);
    setEdiVariables(undefined);
  }, [updateSuccess]);

  //useEffect for save success for duplication
  useEffect(() => {
    if (templateRefId === undefined) return;

    if (saveSuccess) {
      createCategoryIfNotExists();
      success(t('message.success.tmeplateDuplicated'));
    }

    if (saveError) {
      error(t('message.error.templateDuplicationFailed'));
    }

    setName('');
    setDescription('');
    setPriority(0);
    setCategory('');
    setModalId(undefined);
    setMainTemplate(undefined);
    setIsDuplicating(false);
    setEdiVariables(undefined);
    setEdiOption(undefined);
  }, [saveSuccess, saveError]);

  //useEffect for templateRef success for duplication
  useEffect(() => {
    if (!isDuplicating || !openDuplicate || !refSuccess) return;
    if (duplTemplateFetched === undefined) return;

    const { id: duplicatedTemplateId } = templateRefId;
    const {
      id: mainId,
      name: mainName,

      ...templateWithoutIdandName
    } = duplTemplateFetched;

    const template = {
      ...templateWithoutIdandName,
      refId: duplicatedTemplateId,
      name,
    };

    createTemplateSave(template);
    setDuplicateOpen(false);
  }, [isDuplicating, refSuccess, duplTemplateFetched]);

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handlePriority = (value: number | '') => {
    if (value === '') {
      setPriority(0);
      return;
    }

    setPriority(value);
  };

  const handleCategory = (value: string | null) => {
    if (value === null) return;
    setCategory(value);
  };

  const handleSourceChange = (source: SchemaSource) => {
    setSource(source);

    if (source !== SchemaSource.Http) {
      setEdiOption(undefined);
      setEdiVariables(undefined);
    }
  };

  const handleEdiOptionChange = (option: EdiOptions) => {
    setEdiVariables(undefined);
    setEdiOption(option);
  };

  const handleInputChange = (
    id: string,
    value: string | number,
    schemaVariable: string
  ): void => {
    setEdiVariables((prev) => ({
      ...prev,
      [id]: { value, schemaVariable },
    }));
  };

  const processInternalFields = (ediOption: EdiOptions, inputValues: Record<string, { value: string | number; schemaVariable: string }> = {}) => {
    const updatedValues = { ...inputValues };

    ediOptionInputs[ediOption]?.forEach((input) => {
      if (input.internal) {
        switch (input.id) {
          case 'oauth': {
            const user = inputValues['user']?.value;
            const password = inputValues['password']?.value;
            if (user && password) {
              const oauthValue = Buffer.from(`${user}:${password}`).toString('base64'); // Base64 encode
              updatedValues['oauth'] = {
                value: oauthValue,
                schemaVariable: input.schemaVariable,
              };
            }
            break;
          }
          //Extend for additional internal EDI fields in the future
          default:
            warn(`No processing logic for internal field: ${input.id}`);
        }
      }
    });
  
    return updatedValues;
  };

  const handleEditTemplate = () => {
    if (modalId === undefined) return;

    const processedEdiInputValues =
      source === SchemaSource.Http && ediOption
        ? processInternalFields(ediOption, ediVariables)
        : ediVariables;

    setNameError('');
    updateTemplate({
      id: modalId,
      template: {
        name,
        description,
        priority,
        category,
        inputsource: source,
        ediType: ediOption,
        ediVariables: processedEdiInputValues,
      },
    });
  };

  const handleDuplicateTemplate = () => {
    setNameError('');
    setIsDuplicating(true);
    createTemplateRef({
      operatorId,
      deployPath: null,
      name,
      domain,
      templatetype: 'MorphMapper',
      category,
      enabled: false,
      priority,
      description,
      inputsource: source,
      ediType: ediOption,
      ediVariables,
    });
  };

  const handleCloseModal = () => {
    setOpenEdit(false);
    setDuplicateOpen(false);

    setNameError('');
    setName('');
    setDescription('');
    setPriority(0);
    setCategory('');
    setModalId(undefined);
    setMainTemplate(undefined);
    setEdiVariables(undefined);
    setEdiOption(undefined);
  };

  const createCategoryIfNotExists = async () => {
    if (categories === undefined) return;

    if (categories.every(({ name }) => name !== category)) {
      addCategory({ operatorId, name: category, domain });
    }
  };

  const checkNameConflict = async () => {
    if (templates === undefined) return;
    const isNameDuplicate = templates.find(
      (template) =>
        template.domain === domain &&
        template.category === category &&
        template.name === name &&
        template.deleted !== true &&
        (openDuplicate || template.id !== modalId)
    );
    if (isNameDuplicate) {
      setNameError(
        'A template with this name already exists in the selected category'
      );
    } else {
      setNameError('');
    }
  };

  const canSaveTemplate = () => {
    if (nameError !== '') return false;
    if (source === SchemaSource.Http) {
      if (!ediOption) return false;
      const allInputsFilled = ediOptionInputs[ediOption]?.every(
        (input) =>
          ediVariables?.[input.id]?.value !== undefined &&
        ediVariables?.[input.id]?.value !== ''
      );
      if (!allInputsFilled) return false;
    }
    return true;
  };

  return {
    name,
    nameError,
    description,
    priority,
    category,
    source,
    ediOption,
    ediVariables,
    handleName,
    handleDescription,
    handlePriority,
    handleCategory,
    handleSourceChange,
    handleEditTemplate,
    handleDuplicateTemplate,
    handleCloseModal,
    handleEdiOptionChange,
    handleInputChange,
    canSaveTemplate,
  };
};
