import { Group } from '@mantine/core';
import { StyledHeader } from './styles';
import { Move } from '@morph-mapper/ui';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';


export const Header = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <StyledHeader>
      <Group>
        <Move into={false} onClick={handleGoBack} />
        {t('header.selectComponent')}
      </Group>
    </StyledHeader>
  );
};
