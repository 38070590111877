import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ZodType } from 'zod';
import { StoreSlice, IGraphEntry } from '@/store';
import { NavigateFunction } from 'react-router-dom';
import { EntryItemType, EntryType } from '@morph-mapper/types';

export interface GraphEntryProps {
  id: string;

  // state for entry in-row editing
  tempValue: any;

  // UI state for entry in-row editing
  isConfirm: boolean;
  isEditing: boolean;
}

export type InitGraphEntryProps = Pick<GraphEntryProps, 'id'>;

export interface GraphEntryState extends GraphEntryProps {
  // Routing
  navigate: NavigateFunction;

  getId: () => string;
  getType: () => EntryType;
  setType: (type: EntryItemType) => void;
  getName: () => string | undefined;
  getComputed: () => any;
  getValidation: () => ZodType<any> | undefined;

  // functions for entry in-row editing
  setTempValue: (tempValue: any) => void;
  setConfirm: (toggle: boolean) => void;
  setEditing: (toggle: boolean) => void;

  cancelDelete: () => void;
  confirmDelete: () => void;
  onEdit: () => void;
  onClear: () => void;
  onDelete: () => void;
}

export type InitGraphEntryState = Pick<GraphEntryState, 'navigate'>;

export type GraphEntryStore = ReturnType<typeof createGraphEntryStore>;

export const createGraphEntryStore = (
  global: StoreSlice,
  initProps: InitGraphEntryProps & InitGraphEntryState
) => {
  const DEFAULT_VALUES: Omit<GraphEntryProps, keyof InitGraphEntryProps> = {
    tempValue: undefined,
    isConfirm: false,
    isEditing: false,
  };

  return createStore<GraphEntryState & StoreSlice & IGraphEntry>()(
    immer((set, get) => ({
      ...global,
      ...global.entries.entry(initProps.id, EntryType.Graph),
      ...DEFAULT_VALUES,
      ...initProps,

      setTempValue: (tempValue) =>
        set((s) => {
          s.tempValue = tempValue;
        }),
      setConfirm: (toggle) => {
        set((s) => {
          s.isConfirm = toggle;
        });
      },
      setEditing: (toggle) => {
        set((s) => {
          s.isEditing = toggle;
        });
      },
      cancelDelete: () => {
        set((s) => {
          s.isConfirm = false;
        });
      },
      confirmDelete: () => {
        set((s) => {
          const graphId = s.getGraphId();
          if (graphId !== undefined) {
            s.graphs.delete(graphId);
          }

          s.clear();

          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      onEdit: () => {
        get().navigate(`${get().id}/graph`);
      },
      onClear: () => {
        set((s) => {
          s.clear();

          s.isConfirm = true;
          s.isEditing = false;
        });
      },
      onDelete: () => {
        get().delete();
      },
    }))
  );
};
