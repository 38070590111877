import { getEntries, getKeys } from '@morph-mapper/utils';
import { BlockRule, LogicBlockRecord } from '../types';

/**
 * Transforms the rules of a logic block from an array to a set. We use a set to take advantage of the faster lookup time.
 * While using an array in the configuration object for reduced complexity.
 */
export const transformRulesToSet = <T extends Record<keyof T, any>>(
  map: LogicBlockRecord<T, BlockRule[]>
): LogicBlockRecord<T, Set<BlockRule>> => {
  return getKeys(map).reduce((acc, key) => {
    const logic = map[key];
    acc[key] = {
      ...logic,
      rules: new Set(logic.rules),
    };
    return acc;
  }, {} as LogicBlockRecord<T, Set<BlockRule>>);
};

/**
 * Wraps the mutation function of a logic block with additional checks based on the rules of the logic block.
 * It further modifies the tree function to return the correct amount of children.
 */
export const wrapValidation = <T extends Record<keyof T, any>>(
  map: LogicBlockRecord<T, Set<BlockRule>>
) => {
  // Iterate over each key in the map, add additional checks to the mutation function
  for (const key in map) {
    const logic = map[key];
    const { mutation } = logic;

    map[key].mutation = ({ tree, options, ctx }) => {
      const children = tree();

      if (logic.rules.has(BlockRule.NoChildren)) {
        if (children !== undefined && children.length > 0) {
          throw new Error('Render function cannot have any children.');
        }

        return mutation({ options, tree: () => undefined, ctx });
      }

      if (logic.rules.has(BlockRule.SingleChild)) {
        if (children !== undefined && children.length > 1) {
          throw new Error('Render function can only have one child.');
        }

        return mutation({
          options,
          tree: () => (children !== undefined ? children[0] : undefined),
          ctx,
        });
      }

      return mutation({ tree, options, ctx });
    };
  }
  return map;
};

/**
 * Adds the translation keys to the logic block configuration object. These keys are hydrated into human readable text
 * by i18n in React Components, based on the active language.
 */
export const translate = <T extends Record<keyof T, any>>(
  map: LogicBlockRecord<T, Set<BlockRule>>
) => {
  return getKeys(map).reduce((acc, key) => {
    const logic = map[key];

    // Build the translation keys based on how the translation files are structured
    const baseKey = `logicBlocks.${logic.category}.${logic.type}`;
    const titleKey = `${baseKey}.title`;
    const descriptionKey = `${baseKey}.description`;

    // Build the translation keys of all options
    const optionKeys = getEntries(logic.options).reduce(
      (acc, [optionKey, option]) => {
        const optionBaseKey = `${baseKey}.options.${String(optionKey)}`;

        return {
          ...acc,
          [optionKey]: {
            ...option,
            title: `${optionBaseKey}.title`,
            description: `${optionBaseKey}.description`,
          },
        };
      },
      {}
    );

    const translated = {
      ...logic,
      options: optionKeys,
      title: titleKey,
      description: descriptionKey,
    };

    return {
      ...acc,
      [key]: translated,
    };
  }, {} as LogicBlockRecord<T, Set<BlockRule>>);
};
