import { SchemaVariant } from '@morph-mapper/types';
import { STRUCTURE_BLOCKS as structureBlocks } from './base';

export * from './base/shared';

export const STRUCTURE_BLOCKS_RULESET = {
  [SchemaVariant.Pdf]: structureBlocks,
  [SchemaVariant.Table]: structureBlocks,
  [SchemaVariant.Xml]: structureBlocks,
  [SchemaVariant.Email]: structureBlocks,
  [SchemaVariant.Text]: structureBlocks,
  [SchemaVariant.Json]: structureBlocks,
};
