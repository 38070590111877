import { z } from 'zod';

export const templateRefSchema = z.object({
  id: z.string(),
  operatorId: z.number(),
  name: z.string(),
  path: z.string().nullable(),
  deployPath: z.string().nullable(),
  // TODO: where place domain
  // TODO: should be enum
  templatetype: z.string(),
  domain: z.string(),
  category: z.string(),
  priority: z.number(),
  inputsource: z.string(),
  description: z.string().optional(),
  enabled: z.boolean(),
  deleted: z.boolean().optional(),
  ediType: z.string().optional(), 
  ediVariables: z.record(
    z.string(),
    z.object({
      value: z.union([z.string(), z.number()]), 
      schemaVariable: z.string().optional(),
    })
  ).optional(),
});

export type TemplateRef = z.infer<typeof templateRefSchema>;

// TODO: reduce duplication?
// TODO: stronger typing
export const templateSaveSchema = z.object({
  id: z.string(),
  operatorId: z.number(),
  name: z.string(),
  domain: z.string(),
  type: z.string(),
  variant: z.string(),
  entries: z.record(z.string(), z.any()),
  graphs: z.record(z.string(), z.any()),
  config: z.object({
    preconfiguration: z.any(),
    updateDiscardIds: z.array(z.string()),
  }),
});

export type TemplateSave = z.infer<typeof templateSaveSchema>;

export const templateDeploySchema = z.object({
  id: z.string(),
  operatorId: z.number(),
  name: z.string(),
  domain: z.string(),
  type: z.string(),
  variant: z.string(),
  file: z.string(),
});

export type TemplateDeploy = z.infer<typeof templateDeploySchema>;

export const templateCategorySchema = z.object({
  id: z.string(),
  operatorId: z.number(),
  domain: z.string(),
  name: z.string(),
});

export type TemplateCategory = z.infer<typeof templateCategorySchema>;

export const rawTemplateDeploySchema = z.object({
  deployPath: z.string(),
  content: z.string(),
});

export type RawTemplateDeploy = z.infer<typeof rawTemplateDeploySchema>;
