import React from 'react';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App } from './components/core/organisms/app/app';
import { i18n } from '@morph-mapper/shared-i18n';
import { I18nextProvider } from 'react-i18next';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <I18nextProvider i18n={i18n}>
          <Notifications position="top-right" />
          <App />
        </I18nextProvider>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
