import { Badge, Breadcrumbs, Group } from '@mantine/core';
import { BaseHeader, Move } from '@morph-mapper/ui';
import { useTreeContext } from '../../context';
import { useStore } from '@/store';
import { t } from 'i18next';

export const Header = () => {
  const getName = useStore(({ entries: e }) => e.getName);
  const [pointer, moveOut] = useTreeContext((s) => [s.pointer, s.moveOut]);

  return (
    <BaseHeader sx={{ borderBottom: 0 }}>
      <Group>
        {pointer.length > 1 ? <Move into={false} onClick={moveOut} /> : null}
        {t('header.overview')}
      </Group>

      <Group>
        {pointer.length > 0 ? (
          <Badge radius={'sm'} color="cyan">
            <Breadcrumbs separator={'/'}>
              {...pointer.map((p) => getName(p))}
            </Breadcrumbs>
          </Badge>
        ) : null}
      </Group>
    </BaseHeader>
  );
};
