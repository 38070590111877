import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ZodType } from 'zod';
import { StoreSlice, ICellEntry } from '@/store';
import { NavigateFunction } from 'react-router-dom';
import { EntryItemType, EntryType } from '@morph-mapper/types';

export interface CellEntryProps {
  id: string;

  // state for entry in-row editing
  backupValue: number | undefined;

  // UI state for entry in-row editing
  isConfirm: boolean;
  isEditing: boolean;
}

export type InitCellEntryProps = Pick<CellEntryProps, 'id'>;

export interface CellEntryState extends CellEntryProps {
  // Routing
  navigate: NavigateFunction;

  getId: () => string;
  getType: () => EntryType;
  setType: (type: EntryItemType) => void;
  getName: () => string | undefined;
  getComputed: () => any;
  setComputed: (computed: any) => void;
  getValidation: () => ZodType<any> | undefined;

  // functions for entry in-row editing
  setNewValue: (newValue: any) => void;
  setConfirm: (toggle: boolean) => void;
  setEditing: (toggle: boolean) => void;

  cancelDelete: () => void;
  confirmDelete: () => void;
  cancelEdit: () => void;
  confirmEdit: () => void;
  onEdit: () => void;
  onClear: () => void;
  onDelete: () => void;
}

export type InitCellEntryState = Pick<CellEntryState, 'navigate'>;

export type CellEntryStore = ReturnType<typeof createCellEntryStore>;

export const createCellEntryStore = (
  global: StoreSlice,
  initProps: InitCellEntryProps & InitCellEntryState
) => {
  const DEFAULT_VALUES: Omit<CellEntryProps, keyof InitCellEntryProps> = {
    backupValue: undefined,
    isConfirm: false,
    isEditing: false,
  };

  return createStore<CellEntryState & StoreSlice & ICellEntry>()(
    immer((set, get) => ({
      ...global,
      ...global.entries.entry(initProps.id, EntryType.Cell),
      ...DEFAULT_VALUES,
      ...initProps,

      setNewValue: (newValue) =>
        set((s) => {
          s.setColumn(newValue);
        }),
      setConfirm: (toggle) => {
        set((s) => {
          s.isConfirm = toggle;
        });
      },
      setEditing: (toggle) => {
        set((s) => {
          s.isEditing = toggle;
        });
      },
      cancelDelete: () => {
        set((s) => {
          s.isConfirm = false;
        });
      },
      confirmDelete: () => {
        set((s) => {
          s.clear();

          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      cancelEdit: () => {
        set((s) => {
          s.setColumn(s.backupValue);

          s.backupValue = 0;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      confirmEdit: () => {
        set((s) => {
          s.backupValue = 0;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      onEdit: () => {
        set((s) => {
          s.backupValue = s.getColumn();

          s.isEditing = true;
        });
      },
      onClear: () => {
        set((s) => {
          s.clear();

          s.isConfirm = true;
          s.isEditing = false;
        });
      },
      onDelete: () => {
        set((s) => {
          s.isConfirm = true;
          s.isEditing = false;
        });
      },
    }))
  );
};
