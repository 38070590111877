import { Box, Card, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { useBaseNode } from './useBaseNode';
import { type SchemaVariant } from '@morph-mapper/types';
import { LogicBlockIndex, useLogicBlocks } from '@morph-mapper/node-logic';
import { t } from 'i18next';

interface BaseNodeProps {
  type: LogicBlockIndex;
  preAction?: React.ReactNode;
  postAction?: React.ReactNode;
  variant: SchemaVariant;
}

export const BaseNode = ({
  type,
  preAction,
  postAction,
  variant,
}: BaseNodeProps) => {
  const { logicBlocks } = useLogicBlocks(variant);
  const { title, category, icon: Icon } = logicBlocks[type];
  const { getTemplateHandles } = useBaseNode(type, variant);

  return (
    <Box sx={{ position: 'relative' }}>
      <Card withBorder radius="md">
        <Card.Section inheritPadding py="xs" withBorder>
          <Group>
            {preAction}
            <ThemeIcon variant="default" size="xl">
              <Icon />
            </ThemeIcon>
            <Stack spacing={2} mr="xl">
              <Title order={6}>{t(title)}</Title>
              <Text size="xs" c="dimmed" tt="capitalize" sx={{ maxWidth: 200 }}>
                {category}
              </Text>
            </Stack>
            {postAction}
          </Group>
        </Card.Section>
      </Card>

      {...getTemplateHandles()}
    </Box>
  );
};
