import { useNotify } from '@/hooks';
import { useEffect, useState } from 'react';
import {
  useCategoriesOfOperatorIdByDomain,
  useDeleteCategory,
  useTemplateRefsOfOperatorId,
} from '@/services';
import { t } from 'i18next';

export const useCategoryItemDelete = (
  operatorId: number,
  domain: string,
  categoryName: string
) => {
  /* Hooks */
  const { success, warn, error } = useNotify();
  const { data: categories } = useCategoriesOfOperatorIdByDomain(
    operatorId,
    domain
  );
  const { data: templates } = useTemplateRefsOfOperatorId(operatorId);
  const { mutate: deleteCategory, isError, isSuccess } = useDeleteCategory();

  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);

  // Get the id of the category
  useEffect(() => {
    if (categories === undefined) return;

    const categoryToDelete = categories.find(
      (category) => category.name === categoryName
    );

    if (categoryToDelete) {
      setCategoryId(categoryToDelete.id);
    }
  }, [categories, categoryName]);

  // Notify user of success or error
  useEffect(() => {
    if (isSuccess) {
      success(t('message.success.categoryDeleted'));
    }
    if (isError) {
      error(t('message.error.categoryDeleting'));
    }
  }, [isSuccess, isError]);

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    // Check if the category is deletable
    const categoryHasTemplates = templates?.some(
      (template) =>
        template.category === categoryName &&
        template.domain === domain &&
        template.deleted !== true
    );

    if (categoryHasTemplates) {
      warn(t('message.warning.categoryNotEmpty'));
      return;
    }

    if (categoryId) {
      deleteCategory(categoryId);
    } else {
      warn(t('message.warning.categoryNotFound'));
    }
  };

  return { handleDelete };
};
