import { Cancel, Complete, Edit, Options } from '@morph-mapper/ui';
import { Group, Menu, Tooltip, useMantineTheme } from '@mantine/core';
import { StyledActionCell } from './styles';
import {
  useItemEntryContext,
  useInlineEntryContext,
  useTreeContext,
} from '../../context';
import { LuClipboardPaste, LuCopy, LuEraser } from 'react-icons/lu';
import { TiWarningOutline } from 'react-icons/ti';
import { FiTrash2 } from 'react-icons/fi';
import { TypeChangeMenuSection } from '../type-change-menu-section';
import { useStore } from '@/store';

export const CopyPaste = () => {
  const IEntry = useStore(({ entries: e }) => e.entry);
  const [copySourceId, copyEntry, pasteEntry] = useTreeContext((s) => [
    s.copySourceId,
    s.copyEntry,
    s.pasteEntry,
  ]);
  const [entryId, hasValue, targetType] = useItemEntryContext((s) => [
    s.getId(),
    s.hasValue(),
    s.getType(),
  ]);

  const theme = useMantineTheme();

  return (
    <>
      {/* Only show the copy option if there is a value to copy. */}
      {hasValue && (
        <Menu.Item
          icon={<LuCopy color={theme.colors.gray[6]} />}
          onClick={() => copyEntry(entryId)}
        >
          Copy
        </Menu.Item>
      )}

      {/* Only show the paste option when something has been actively copied. */}
      {copySourceId !== null && (
        <Menu.Item
          icon={<LuClipboardPaste color={theme.colors.gray[6]} />}
          onClick={() => pasteEntry(entryId)}
          rightSection={
            // Give the user a warning that they are about to overwrite the type.
            targetType !== IEntry(copySourceId).getType() && (
              <Tooltip label="The types do not match, pasting will overwrite the current type.">
                <div>
                  <TiWarningOutline color={theme.colors.yellow[8]} />
                </div>
              </Tooltip>
            )
          }
        >
          Paste
        </Menu.Item>
      )}
    </>
  );
};

export const EditActions = () => {
  const [hasValue, onEdit, onClear, onDelete] = useItemEntryContext((s) => [
    s.hasValue(),
    s.onEdit,
    s.onClear,
    s.onDelete,
  ]);
  const theme = useMantineTheme();

  return (
    <StyledActionCell>
      <Group>
        <Edit onClick={onEdit} />
        <Menu shadow="md" width={150}>
          <Menu.Target>
            <Options />
          </Menu.Target>
          <Menu.Dropdown>
            <TypeChangeMenuSection />
            <Menu.Label>Actions</Menu.Label>
            <CopyPaste />
            <Menu.Item
              icon={<LuEraser color={theme.colors.gray[6]} />}
              onClick={onClear}
              disabled={!hasValue}
            >
              Clear
            </Menu.Item>
            <Menu.Item
              icon={<FiTrash2 color={theme.colors.red[6]} />}
              onClick={onDelete}
            >
              Remove
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </StyledActionCell>
  );
};

export const ConfirmActions = () => {
  const [confirmEdit, cancelEdit] = useInlineEntryContext((s) => [
    s.confirmEdit,
    s.cancelEdit,
  ]);

  return (
    <StyledActionCell>
      <Group>
        <Complete onClick={confirmEdit} />
        <Cancel onClick={cancelEdit} />
      </Group>
    </StyledActionCell>
  );
};

export const ActionCell = () => {
  const [isEditing] = useItemEntryContext((s) => [s.isEditing]);

  return (
    <StyledActionCell>
      <Group>{isEditing ? <ConfirmActions /> : <EditActions />}</Group>
    </StyledActionCell>
  );
};
