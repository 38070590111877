import { Group, Button, Menu, ActionIcon, useMantineTheme } from '@mantine/core';
import { Stepper } from '../../molecules';
import { useSetup } from './use-setup';
import { Container, LeftColumn, RightColumn, StepWrapper } from './styles';
import { useEffect } from 'react';
import { MdOutlineLanguage } from 'react-icons/md';
import { t } from 'i18next';


export const Setup = () => {
  const theme = useMantineTheme();
  const { init, step, handlePrevious, renderStep, handleLanguageChange, opId } = useSetup();
  const Step = renderStep();

  useEffect(() => {
    init();
  }, []);

  if (!opId) {
    return;
  }

  return (
    <Container>
      <LeftColumn>
        <StepWrapper>
          <Step>
            {(progress, disabled) => (
              <Group position="apart">
                <Button
                  variant="default"
                  onClick={handlePrevious}
                  sx={{ visibility: step !== 0 ? 'visible' : 'hidden' }}
                >
                  {t('button.previous')}
                </Button>
                <Button
                  disabled={disabled}
                  onClick={progress}
                  sx={{ visibility: step !== 1 ? 'visible' : 'hidden' }}
                >
                  {t('button.next')}
                </Button>
              </Group>
            )}
          </Step>
        </StepWrapper>
      </LeftColumn>

      <RightColumn>
        <Group position="center">
          <Menu shadow="md" position="bottom-end">
            <Menu.Target>
              <ActionIcon
                variant="default"
                size={'md'}
                sx={{
                  position: 'absolute',
                  top: '1rem',
                  right: '1rem',
                  border: `1px solid ${theme.colors.gray[3]}`,
                }}
              >
                <MdOutlineLanguage size="1rem" color={theme.colors.gray[7]}/>
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={handleLanguageChange} data-lang="en">
                English
              </Menu.Item>
              <Menu.Item onClick={handleLanguageChange} data-lang="nl">
                Nederlands
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Stepper step={step} />
        </Group>
      </RightColumn>
    </Container>
  );
};
