import { Button, Group } from '@mantine/core';
import { StyledHeader } from './styles';
import { Move } from '@morph-mapper/ui';
import { useNodeContext } from '../../context';
import { IoHelpCircleSharp } from 'react-icons/io5';
import { useStore } from '@/store';
import { t } from 'i18next';

export const Header = () => {
  const navigate = useNodeContext((s) => s.navigate);
  const [setIsDocModalOpen] = useStore(({ config: c }) => [
    c.setIsDocModalOpen,
  ]);

  const handleClose = () => {
    navigate(-1);
  };

  const handleHelpClick = () => {
    setIsDocModalOpen(true);
  };

  return (
    <StyledHeader>
      <Group>
        <Move into={false} onClick={handleClose} />
        {t('header.configureComponent')}
        <Button
          variant="light"
          compact
          onClick={handleHelpClick}
          style={{ padding: '0px' }}
        >
          <IoHelpCircleSharp size={20} />
        </Button>
      </Group>
    </StyledHeader>
  );
};
