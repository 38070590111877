import { z } from 'zod';
import { IconType } from 'react-icons';
import type { GraphEdge, GraphNode } from './elements';
import { LOGIC_BLOCKS_RULESET, LOGIC_CATEGORIES } from '../logic';
import { Handle } from '@morph-mapper/types';

export type LogicBlockRules = {
  configurable: boolean;
  multipleChildren: boolean;
};

export type OptionConditions = Partial<{
  // this record can be more strictly typed by options
  hasValue: Record<string, any>;
  isConnector: boolean;
  isFullWidth: boolean;
  isStatic: boolean;
  isHidden: boolean;
}>;

export type OptionConfig = {
  type: z.ZodType<any>;
  title: string;
  description: string;
  input: any;
  selection?: {
    label: string;
    value: any;
  }[];
  default: any;
  conditions: OptionConditions;
};

export type OptionsRecord = Record<string, OptionConfig>;

export type OptionsAsArgument<T extends OptionsRecord> = {
  [Property in keyof T]: z.infer<T[Property]['type']>;
};

export enum DIRECTION {
  TARGET = 'target',
  SOURCE = 'source',
}

export type HandleConfig = {
  [DIRECTION.SOURCE]: readonly Handle[];
  [DIRECTION.TARGET]: readonly Handle[];
};

// For rules we only check the positive case.
export type BlockRules = {
  configurable: boolean;
  multipleChildren: boolean;
  singleChild: boolean;
  noChildren: boolean;
  multipleElement: boolean;
};

export enum BlockRule {
  Configurable = 'configurable',
  MultipleChildren = 'multipleChildren',
  SingleChild = 'singleChild',
  NoChildren = 'noChildren',
  MultipleElement = 'multipleElement',
}

export type LogicBlockConfig<K extends string, R, D extends OptionsRecord> = {
  title: string;
  category: keyof typeof LOGIC_CATEGORIES | 'internal';
  type: K;
  description: string;
  node: GraphNode;
  icon: IconType;
  options: D;
  handles: HandleConfig;
  mutation: MutationWithChildren<OptionsAsArgument<D>>;
  rules: R;
};

export type MutationContext = {
  /*
   * Determines if the current node is the root node of the logic tree.
   * This is used in cases where depending on the location of the node in the tree
   * we want to prevent rendering of certain elements,
   * for example when rendering may lead to duplication of the associated entry key.
   */
  isRoot: boolean;
};

export type MutationWithChildren<T> = (args: {
  options: T;
  tree: () => any;
  ctx: MutationContext;
}) => any;

export type LogicBlockRecord<T extends Record<keyof T, any>, R> = {
  [K in keyof T]: LogicBlockConfig<K & string, R, T[K]>;
};

export const typeLogicBlockRecord = <T extends Record<keyof T, any>>(
  map: LogicBlockRecord<T, BlockRule[]>
) => map;

export type LogicBlockIndex =
  keyof typeof LOGIC_BLOCKS_RULESET[keyof typeof LOGIC_BLOCKS_RULESET];
export type OptionConfigKey<T extends LogicBlockIndex> =
  keyof typeof LOGIC_BLOCKS_RULESET[keyof typeof LOGIC_BLOCKS_RULESET][T]['options'];

type LogicConnectorConfig = {
  edge: GraphEdge;
};

type ConnectorRecord<T extends Record<keyof T, any>> = {
  [K in keyof T]: LogicConnectorConfig;
};

export const typeConnectorRecord = <
  T extends Record<keyof T, LogicConnectorConfig>
>(
  map: ConnectorRecord<T>
) => map;
