import { SchemaVariant } from '@morph-mapper/types';
import { SchemaType } from '../../types';
import { retailDefaultSchema } from './standard/default';
import { retailTableSchema } from './standard/table';
import { retailXmlSchema } from './standard/xml';
import { retailTextSchema } from './standard/text';
import { retailEmailSchema } from './standard/email';
import { customRetailTableSchema } from './standard/tableExtra';
import { retailJsonSchema } from './standard/json';

export const types = {
  [SchemaType.Standard]: {
    [SchemaVariant.Pdf]: retailDefaultSchema,
    [SchemaVariant.Table]: retailTableSchema,
    [SchemaVariant.Xml]: retailXmlSchema,
    [SchemaVariant.Text]: retailTextSchema,
    [SchemaVariant.Email]: retailEmailSchema,
    [SchemaVariant.Json]: retailJsonSchema,
  },
};

export const customTypes = {
  [SchemaType.Standard]: {
    [SchemaVariant.Table]: {
      customRetailTableSchema,
    },
  },
};
