import {
  Box,
  Button,
  Group,
  Modal,
  NumberInput,
  Radio,
  Select,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useCreateTemplate } from './use-create-template';
import { ediOptionInputs, EdiOptions, SchemaSource } from '@morph-mapper/types';
import { getKeys } from '@morph-mapper/utils';
import { t } from 'i18next';

export const CreateTemplateModal = () => {
  const {
    open,
    name,
    nameError,
    description,
    priority,
    category,
    type,
    source,
    variant,
    categories,
    ediOption,
    ediInputValues,
    canCreateTemplate,
    handleName,
    handleDescription,
    handlePriority,
    handleCategory,
    handleCreateTemplate,
    handleCloseModal,
    getSchemaTypes,
    getSchemaVariants,
    handleTypeChange,
    handleVariantChange,
    handleSourceChange,
    handleEdiOptionChange,
    handleInputChange,
  } = useCreateTemplate();

  if (categories === undefined) return null;

  return (
    <Modal
      opened={open}
      onClose={handleCloseModal}
      title={t('title.newTemplate')}
      centered
      size="30%"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 300,
          height: '65vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            overflowY: 'auto',
            flex: 1,
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '0.5rem',
            marginBottom: '2rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              minHeight: '80px',
            }}
          >
            <TextInput
              label={t('common.name')}
              value={name}
              error={nameError}
              onChange={handleName}
              withAsterisk
              data-autofocus
            />
          </Box>

          <Radio.Group
            label={t('common.inputSource')}
            withAsterisk
            onChange={handleTypeChange}
            value={type}
          >
            <Group mt="md">
              {getSchemaTypes().map((type) => {
                const label = type.charAt(0).toUpperCase() + type.slice(1);
                return <Radio key={type} value={type} label={label} />;
              })}
            </Group>
          </Radio.Group>

          {type && (
            <Radio.Group
              label={t('common.templateVariant')}
              withAsterisk
              onChange={handleVariantChange}
              value={variant}
            >
              <Group mt="md">
                {getSchemaVariants(type).map((variant) => {
                  const label =
                    variant.charAt(0).toUpperCase() + variant.slice(1);
                  return <Radio key={variant} value={variant} label={label} />;
                })}
              </Group>
            </Radio.Group>
          )}
          {type && (
            <Radio.Group
              label={t('common.inputSource')}
              withAsterisk
              onChange={handleSourceChange}
              value={source}
            >
              <Group mt="md">
                {getKeys(SchemaSource).map((source) => {
                  const label =
                    source.charAt(0).toUpperCase() + source.slice(1);
                  return (
                    <Radio
                      key={source}
                      value={SchemaSource[source]}
                      label={label}
                    />
                  );
                })}
              </Group>
            </Radio.Group>
          )}
          {source === SchemaSource.Http && (
            <Radio.Group
              label={t('common.ediOptions')}
              withAsterisk
              onChange={handleEdiOptionChange}
              value={ediOption}
            >
              <Group mt="md">
                {getKeys(EdiOptions).map((optionKey) => {
                  const label =
                    optionKey.charAt(0).toUpperCase() + optionKey.slice(1);
                  return (
                    <Radio
                      key={optionKey}
                      value={EdiOptions[optionKey]}
                      label={label}
                    />
                  );
                })}
              </Group>
            </Radio.Group>
          )}
          {source === SchemaSource.Http && ediOption && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {ediOptionInputs[ediOption]?.map((input) => {
                if (input.internal) return null;

                const currentValue = ediInputValues?.[input.id]?.value ?? '';
                switch (input.type) {
                  case 'text':
                    return (
                      <TextInput
                        key={input.id}
                        label={input.label}
                        withAsterisk
                        value={currentValue}
                        onChange={(e) =>
                          handleInputChange(
                            input.id,
                            e.target.value,
                            input.schemaVariable
                          )
                        }
                      />
                    );
                  case 'number':
                    return (
                      <NumberInput
                        key={input.id}
                        label={input.label}
                        withAsterisk
                        value={
                          typeof currentValue === 'number'
                            ? currentValue
                            : undefined
                        }
                        onChange={(val) =>
                          handleInputChange(
                            input.id,
                            val ?? 0,
                            input.schemaVariable
                          )
                        }
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Select
              label={t('common.category')}
              value={category}
              data={categories.map(({ name }) => name)}
              onChange={handleCategory}
              withAsterisk
            />

            <NumberInput
              label={t('common.priority')}
              value={priority}
              onChange={handlePriority}
              withAsterisk
            />
          </Box>

          <Textarea
            label={t('common.description')}
            value={description}
            onChange={handleDescription}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={handleCreateTemplate}
            disabled={!canCreateTemplate()}
          >
            {t('button.create')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
