import {
  LogicBlockIndex,
  OptionsRecord,
  useLogicBlocks,
} from '@morph-mapper/node-logic';
import { useStore } from '@/store';

export const useConfigView = () => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const { logicBlocks } = useLogicBlocks(variant);

  const selectContent = (type: LogicBlockIndex): OptionsRecord => {
    const block = logicBlocks[type];

    if (block.category === 'internal') {
      throw new Error('Internal components do not have a configuration.');
    }

    return block.options;
  };

  return { selectContent };
};
