import { SchemaVariant } from '@morph-mapper/types';
import { SchemaType } from '../../types';
import {
  interModalDefaultExportSchema,
  interModalEmailExportSchema,
  interModalTableExportSchema,
  interModalTextExportSchema,
  interModalJsonExportSchema,
  interModalXmlExportSchema,
} from './export';
import {
  interModalTableImportSchema,
  interModalDefaultImportSchema,
  interModalEmailImportSchema,
  interModalTextImportSchema,
  interModalJsonImportSchema,
  interModalXmlImportSchema,
} from './import';

export const types = {
  [SchemaType.Export]: {
    [SchemaVariant.Pdf]: interModalDefaultExportSchema,
    [SchemaVariant.Table]: interModalTableExportSchema,
    [SchemaVariant.Xml]: interModalXmlExportSchema,
    [SchemaVariant.Email]: interModalEmailExportSchema,
    [SchemaVariant.Text]: interModalTextExportSchema,
    [SchemaVariant.Json]: interModalJsonExportSchema,
  },
  [SchemaType.Import]: {
    [SchemaVariant.Pdf]: interModalDefaultImportSchema,
    [SchemaVariant.Table]: interModalTableImportSchema,
    [SchemaVariant.Xml]: interModalXmlImportSchema,
    [SchemaVariant.Email]: interModalEmailImportSchema,
    [SchemaVariant.Text]: interModalTextImportSchema,
    [SchemaVariant.Json]: interModalJsonImportSchema,
  },
};
