import { getKeys } from '@morph-mapper/utils';
import { type SchemaVariant } from '@morph-mapper/types';
import { SUPPORTED_SCHEMAS } from '../definitions';
import { CustomSchemes, Schema, SchemaType } from '../types';

// Function to validate domains defined in db against SUPPORTED_SCHEMAS
export function validateDomains(domains: string[] | undefined): { validDomains: string[]; invalidDomains: string[] } {
  if (!domains || domains.length === 0) {
    return { validDomains: [], invalidDomains: [] };
  }

  const validDomains = domains.filter((domain) => SUPPORTED_SCHEMAS[domain]);
  const invalidDomains = domains.filter((domain) => !SUPPORTED_SCHEMAS[domain]);

  return { validDomains, invalidDomains };
}

// Overloaded function signatures

// No parameters
export function useSchema(): {
  getSchemaDomains(): string[];
  getSchemaDomainConfig(domain: string): {
    displayName: string;
    description: string;
  };
  getSchemaTypes(domain: string): SchemaType[];
  getSchemaVariants(domain: string, type: SchemaType): SchemaVariant[];
  getSchema(domain: string, type: SchemaType, variant: SchemaVariant): Schema;
  validateDomains(domains: string[] | undefined): { validDomains: string[]; invalidDomains: string[] };
  getWarnings(): { key: string; params?: Record<string, any> }[];
};

// Single domain with custom schemes
export function useSchema(domain: string, definedCustomSchemes: CustomSchemes | undefined): {
  getSchemaDomains(): string[];
  getSchemaDomainConfig(): { displayName: string; description: string };
  getSchemaTypes(): SchemaType[];
  getSchemaVariants(type: SchemaType): SchemaVariant[];
  getSchema(type: SchemaType, variant: SchemaVariant): Schema;
  validateDomains(domains: string[] | undefined): { validDomains: string[]; invalidDomains: string[] };
  getWarnings(): { key: string; params?: Record<string, any> }[];

};

// With domains and schemes
export function useSchema(domains: string[] | undefined, definedCustomSchemes:CustomSchemes | undefined): {
  getSchemaDomains(): string[];
  getSchemaDomainConfig(domain: string): {
    displayName: string;
    description: string;
  };
  getSchemaTypes(domain: string): SchemaType[];
  getSchemaVariants(domain: string, type: SchemaType): SchemaVariant[];
  getSchema(domain: string, type: SchemaType, variant: SchemaVariant): Schema;
  validateDomains(domains: string[] | undefined): { validDomains: string[]; invalidDomains: string[] };
  getWarnings(): { key: string; params?: Record<string, any> }[];

};

// Implementation
export function useSchema(domainOrDomains?: string | string[], definedCustomSchemes?: CustomSchemes | undefined) {
  const domains = Array.isArray(domainOrDomains) ? domainOrDomains : domainOrDomains ? [domainOrDomains] : [];
  const warnings: { key: string; params?: Record<string, any> }[] = [];

  const addWarning = (key: string, params?: Record<string, any>) => {
    warnings.push({ key, params });
  };

  const schemaDomainRecord = domains.reduce((acc, domain) => {
    const defaultSchemas = SUPPORTED_SCHEMAS[domain];
    const customSchemas = definedCustomSchemes?.[domain] || {};

    // Process only if there are custom schemas for this domain
    if (getKeys(customSchemas).length > 0) {
      const overwrittenSchemas = getKeys(customSchemas).reduce((acc, customSchemaType) => {
        const customType = customSchemas[customSchemaType as SchemaType];
        const defaultType = defaultSchemas.schemas[customSchemaType as SchemaType];

        // Validate against the custom schemas defined in SUPPORTED_SCHEMAS
        const supportedCustomSchemas = defaultSchemas.customSchemas?.[customSchemaType as SchemaType];

        if (!defaultType) {
          addWarning('warning.invalidCustomSchemaType', { customSchemaType, domain });
          return acc;
        } else if (!supportedCustomSchemas) {
          addWarning('warning.noSupportedCustomSchemas', { customSchemaType, domain });
          return acc;
        }

        if (customType) {
          const overwrittenVariants = getKeys(customType).reduce((variantAcc, customSchemaVariant) => {
            const customVariantSchema = customType[customSchemaVariant as SchemaVariant];
            const defaultVariantSchema = defaultType[customSchemaVariant as SchemaVariant];

            if (!defaultVariantSchema) {
              addWarning('warning.invalidCustomSchemaVariant', {
                customSchemaVariant,
                customSchemaType,
                domain,
              });
              return variantAcc;
            }

            if (customVariantSchema) {
              const supportedCustomSchema = supportedCustomSchemas[customSchemaVariant as SchemaVariant];

              // Only overwrite if the custom schema is supported
              if (supportedCustomSchema) {
                const overwritingSchema = supportedCustomSchema[customVariantSchema];
                variantAcc[customSchemaVariant as SchemaVariant] = overwritingSchema;
              } else {
                addWarning('warning.unsupportedCustomSchema', { domain });
              }
            }

            return variantAcc;
          }, { ...defaultType } as Record<SchemaVariant, Schema>);

          acc[customSchemaType as SchemaType] = overwrittenVariants;
        }

        return acc;
      }, {} as Record<SchemaType, Record<SchemaVariant, Schema>>);

      return { ...acc, [domain]: { ...defaultSchemas, schemas: overwrittenSchemas } };
    }

    return { ...acc, [domain]: defaultSchemas };
  }, {} as Record<string, any>);

  const getWarnings = () => warnings;

  const getSchemaDomains = (): string[] => {
    return getKeys(schemaDomainRecord);
  };

  const getSchemaDomainConfig = (domain: string): { displayName: string; description: string } => {
    const config = schemaDomainRecord[domain];
    if (!config) {
      throw new Error('Domain is not supported.');
    }

    const { displayName, description } = config;
    return { displayName, description };
  };

  const getSchemaTypes = (domain: string): SchemaType[] => {
    const config = schemaDomainRecord[domain];
    if (!config) {
      return [];
    }

    return getKeys(config.schemas) as SchemaType[];
  };

  const getSchemaVariants = (domain: string, type: SchemaType): SchemaVariant[] => {
    const config = schemaDomainRecord[domain];
    if (!config) {
      return [];
    }

    const schemaTypeRecord = config.schemas[type];
    if (!schemaTypeRecord) {
      return [];
    }

    return getKeys(schemaTypeRecord) as SchemaVariant[];
  };

  const getSchema = (domain: string, type: SchemaType, variant: SchemaVariant): Schema => {
    const config = schemaDomainRecord[domain];
    if (!config) {
      throw new Error('Domain is not supported.');
    }

    const schemaTypeRecord = config.schemas[type];
    if (!schemaTypeRecord) {
      throw new Error('Type is not supported.');
    }

    const schema = schemaTypeRecord[variant];
    if (!schema) {
      throw new Error('Variant is not supported.');
    }

    return schema;
  };

  // Single domain handling
  if (domains.length === 1) {
    const singleDomain = domains[0];

    return {
      validateDomains,
      getSchemaDomains,
      getSchemaDomainConfig: () => getSchemaDomainConfig(singleDomain),
      getSchemaTypes: () => getSchemaTypes(singleDomain),
      getSchemaVariants: (type: SchemaType) => getSchemaVariants(singleDomain, type),
      getSchema: (type: SchemaType, variant: SchemaVariant) => getSchema(singleDomain, type, variant),
      getWarnings,
    };
  }

  // Multiple domains handling
  return {
    validateDomains,
    getSchemaDomains,
    getSchemaDomainConfig,
    getSchemaTypes,
    getSchemaVariants,
    getSchema,
    getWarnings,
  };
}

