import { Button, Group } from '@mantine/core';
import { StyledHeader } from './styles';
import { useNavigate } from 'react-router-dom';
import { useSchemaConfig } from '@/hooks';
import { IoHelpCircleSharp } from 'react-icons/io5';
import { t } from 'i18next';

export const HeaderConfigDef = () => {
  const { hasFilledRequiredOptions, setIsDocModalOpen } = useSchemaConfig();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('../entries');
  };

  const handleHelpClick = () => {
    setIsDocModalOpen(true);
  };

  return (
    <StyledHeader>
      <Group>
        {t('header.configureSchema')}
        {/* TODO: Implement documentation links for use in configuration panel. */}
        {/* <Button
          variant="light"
          compact
          onClick={handleHelpClick}
          style={{ padding: '0px' }}
        >
          <IoHelpCircleSharp size={20} />
        </Button> */}
      </Group>
      <Button
        variant="default"
        onClick={handleClose}
        sx={{ backgroundColor: 'white' }}
        disabled={hasFilledRequiredOptions()}
      >
        {t('button.continue')}
      </Button>
    </StyledHeader>
  );
};
