import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  fetchDomainsbyOperatorId,
  fetchLanguagebyOperatorId,
  fetchSchemesbyOperatorId,
} from './crud';

export const useDomainsbyOperatorId = (operatorid: string | undefined) => {
  return useQuery({
    queryKey: ['domains-by-operator', operatorid],
    queryFn: operatorid
      ? () => fetchDomainsbyOperatorId(operatorid)
      : skipToken,
    enabled: !!operatorid,
  });
};

export const useSchemesbyOperatorId = (operatorid: string | undefined) => {
  return useQuery({
    queryKey: ['schemes-by-operator', operatorid],
    queryFn: operatorid
      ? () => fetchSchemesbyOperatorId(operatorid)
      : skipToken,
    enabled: !!operatorid,
  });
};

export const useLanguagebyOperatorId = (operatorid: string | undefined) => {
  return useQuery({
    queryKey: ['language-by-operator', operatorid],
    queryFn: operatorid
      ? () => fetchLanguagebyOperatorId(operatorid)
      : skipToken,
    enabled: !!operatorid,
  });
};

