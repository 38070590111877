import { ConfigInput, ConfigLabel } from '@morph-mapper/ui';
import { SectionWrapper } from '../styles';
import { Group } from '@mantine/core';
import { useSchemaConfig, useSchemaEditor } from '@/hooks';
import { getEntries } from '@morph-mapper/utils';
import { Inputs } from '@morph-mapper/node-inputs';
import { useStore } from '@/store';
import { EntryType, ReservedNodeType } from '@morph-mapper/types';
import { t } from 'i18next';

interface ConfigurationOptionProps {
  id: string;
}

type Variable = {
  label: string;
  value: string;
};

export const ConfigurationOption = ({ id }: ConfigurationOptionProps) => {
  const [entry] = useStore(({ entries: e }) => [e.entry]);

  const {
    getKeyById,
    getOptionValue,
    updateConfig,
    getConfigurationByKey,
    getDefaultByKey,
  } = useSchemaConfig();
  const { isValidOption } = useSchemaEditor();

  const recursiveFindDeclareVariables = (entryId: string): string[] => {
    const ids: string[] = [];
    const children = entry(entryId, EntryType.Map).children();

    const foundId = children.getId(ReservedNodeType.DeclareVariable);
    if (foundId !== undefined) {
      ids.push(...entry(foundId, EntryType.Map).children().getIds());
    }

    children
      .getIds()
      .filter((id) => entry(id).getType() === EntryType.Map)
      .map((id) => ids.push(...recursiveFindDeclareVariables(id)));

    return ids;
  };

  const getVariables = (currentId: string) => {
    return recursiveFindDeclareVariables(currentId);
  };

  // 'skip' and 'columns' need the declareVariables in $data.reference format
  const getDeclareVariablesAsData = (currentId: string): Variable[] => {
    const variableIds = getVariables(currentId);

    const declareVariables = variableIds
      .map((id) => {
        if (entry(id).getType() === EntryType.Internal) {
          return undefined;
        }

        return {
          label: entry(id).getName() ?? '',
          value: `$data.${entry(id).getKey()}`,
        };
      })
      .filter((variable): variable is Variable => variable !== undefined);

    return declareVariables;
  };

  const { options } = getConfigurationByKey(getKeyById(id));

  const variableNames = getDeclareVariablesAsData('root');

  return getEntries(options)
    .filter(([optionKey]) => isValidOption(id, optionKey)) // Filter options based on conditions
    .map(
      ([
        optionKey,
        { title, description, input, selection, selectionDeclareVariable },
      ]) => {
        const Input = Inputs[input];
        const titleKey = `configurationOptions.${optionKey}.title`;
        const descriptionKey = `configurationOptions.${optionKey}.description`;

        return (
          <SectionWrapper key={optionKey}>
            <ConfigLabel
              title={t(titleKey, title)}
              description={t(descriptionKey, description)}
            />
            <Group>
              <ConfigInput>
                <Input
                  value={
                    getOptionValue(id, optionKey) ||
                    getDefaultByKey(id, optionKey)
                  }
                  handleChange={(value: any) => {
                    updateConfig(id, optionKey, value);
                  }}
                  selection={
                    selectionDeclareVariable ? variableNames : selection ?? []
                  }
                />
              </ConfigInput>
            </Group>
          </SectionWrapper>
        );
      }
    );
};
