import { ScrollArea, Text } from '@mantine/core';
import { CreateVariable } from '../create-variable';
import { useEntryContext } from '../../context';
import { Panel, Table, TableFooter, TableHeader } from './styles';
import { VariableRow } from '@/common';
import { t } from 'i18next';

export const EnvironmentPanel = () => {
  const variables = useEntryContext((s) => s.getVariableIds());
  return (
    <Panel>
      <Text c="dimmed">{t('panel.environment.description')}</Text>

      <ScrollArea scrollbarSize={2}>
        <Table horizontalSpacing="xl" verticalSpacing="xs">
          <TableHeader>
            <tr>
              <th>{t('panel.environment.table.header.name')}</th>
              <th>{t('panel.environment.table.header.value')}</th>
              <th>{t('panel.environment.table.header.action')}</th>
            </tr>
          </TableHeader>
          <tbody>
            {variables.map((id) => {
              return <VariableRow key={id} id={id} />;
            })}
          </tbody>
          <TableFooter />
        </Table>
      </ScrollArea>
      <CreateVariable />
    </Panel>
  );
};
