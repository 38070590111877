import { SchemaVariant } from '@morph-mapper/types';
import { LOGIC_BLOCKS as defaultBlocks } from './default';
import { LOGIC_BLOCKS as tableBlocks } from './table';
import { LOGIC_BLOCKS as xmlBlocks } from './xml';
import { LOGIC_BLOCKS as emailBlocks } from './email';
import { LOGIC_BLOCKS as textBlocks } from './text';
import { LOGIC_BLOCKS as jsonBlocks } from './json';
import { wrapValidation, transformRulesToSet, translate } from './pipe';

export * from './base/shared';

export const LOGIC_BLOCKS_RULESET = {
  [SchemaVariant.Pdf]: translate(
    wrapValidation(transformRulesToSet(defaultBlocks))
  ),
  [SchemaVariant.Table]: translate(
    wrapValidation(transformRulesToSet(tableBlocks))
  ),
  [SchemaVariant.Xml]: translate(
    wrapValidation(transformRulesToSet(xmlBlocks))
  ),
  [SchemaVariant.Email]: translate(
    wrapValidation(transformRulesToSet(emailBlocks))
  ),
  [SchemaVariant.Text]: translate(
    wrapValidation(transformRulesToSet(textBlocks))
  ),
  [SchemaVariant.Json]: translate(
    wrapValidation(transformRulesToSet(jsonBlocks))
  ),
};
