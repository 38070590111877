import { BaseHeader, Grid } from '@morph-mapper/ui';
import { Box, Group, Menu, Text } from '@mantine/core';
import { useStore } from '@/store';
import { FiSave } from 'react-icons/fi';
import {
  RiLayoutColumnFill,
  RiLayoutRowFill,
  RiTestTubeLine,
  RiQuestionLine,
} from 'react-icons/ri';
import { TbFileExport, TbSettings } from 'react-icons/tb';
import { useHeader } from './use-header';
import { LayoutDirection } from '@/types';
import { MdSaveAlt, MdFirstPage, MdFormatListBulleted } from 'react-icons/md';
import { t } from 'i18next';

export const Header = () => {
  const saveDisabled = useStore(({ config: c }) => c.getIsSaveDisabled());
  const direction = useStore(({ ui }) => ui.layoutDirection);
  const {
    handleTestTemplate,
    handleSaveFile,
    handleExportTemplate,
    handleDeployTemplate,
    handleConfigureTemplate,
    handleDocumentation,
    setLayoutVertical,
    setLayoutHorizontal,
    handleNavigateToTemplateSelection,
    handleNavigateToDomainSelection,
  } = useHeader();

  return (
    <BaseHeader>
      <Text>MorphMapper</Text>
      <Group>
        <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 100 }}>
          <Menu shadow="md" width={150} position="bottom-end">
            <Menu.Target>
              <Grid />
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>{t('menu.templates.label')}</Menu.Label>
              <Menu.Item
                disabled={saveDisabled}
                icon={<RiTestTubeLine />}
                onClick={handleTestTemplate}
              >
                {t('menu.templates.test')}
              </Menu.Item>
              <Menu.Item
                disabled={saveDisabled}
                icon={<FiSave />}
                onClick={handleSaveFile}
              >
                {t('menu.templates.save')}
              </Menu.Item>
              <Menu.Item
                disabled={saveDisabled}
                icon={<MdSaveAlt />}
                onClick={handleExportTemplate}
              >
                {t('menu.templates.localSave')}
              </Menu.Item>
              <Menu.Item
                disabled={saveDisabled}
                icon={<TbFileExport />}
                onClick={handleDeployTemplate}
              >
                {t('menu.templates.deploy')}
              </Menu.Item>
              <Menu.Item
                icon={<TbSettings />}
                onClick={handleConfigureTemplate}
              >
                {t('menu.templates.configure')}
              </Menu.Item>

              <Menu.Label>{t('menu.layout.label')}</Menu.Label>
              {direction === LayoutDirection.Column ? (
                <Menu.Item
                  icon={<RiLayoutRowFill />}
                  onClick={setLayoutHorizontal}
                >
                  {t('menu.layout.horizontal')}
                </Menu.Item>
              ) : (
                <Menu.Item
                  icon={<RiLayoutColumnFill />}
                  onClick={setLayoutVertical}
                >
                  {t('menu.layout.vertical')}
                </Menu.Item>
              )}

              <Menu.Label>{t('menu.documentation.label')}</Menu.Label>
              <Menu.Item
                icon={<RiQuestionLine />}
                onClick={handleDocumentation}
              >
                {t('menu.documentation.help')}
              </Menu.Item>

              <Menu.Label>{t('menu.navigate.label')}</Menu.Label>
              <Menu.Item
                icon={<MdFormatListBulleted />}
                onClick={handleNavigateToTemplateSelection}
              >
                {t('menu.navigate.templateSelection')}
              </Menu.Item>
              <Menu.Item
                icon={<MdFirstPage />}
                onClick={handleNavigateToDomainSelection}
              >
                {t('menu.navigate.domainSelection')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Group>
    </BaseHeader>
  );
};
