import {
  Autocomplete,
  Box,
  Button,
  Group,
  Modal,
  NumberInput,
  Radio,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useConfigureTemplate } from './use-configure-template';
import { useSetupStore } from '../../store';
import { useCategoriesOfOperatorIdByDomain } from '@/services';
import { getKeys } from '@morph-mapper/utils';
import { ediOptionInputs, EdiOptions, SchemaSource } from '@morph-mapper/types';
import { t } from 'i18next';

export const ConfigTemplateModal = () => {
  const [domain, operatorId, openEdit, openDuplicate] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
    s.isEditModalOpen,
    s.isDuplicateModalOpen,
  ]);
  const { data: categories } = useCategoriesOfOperatorIdByDomain(
    operatorId,
    domain
  );
  const {
    name,
    nameError,
    description,
    priority,
    category,
    source,
    ediOption,
    ediVariables,
    handleName,
    handleDescription,
    handlePriority,
    handleCategory,
    handleSourceChange,
    handleEditTemplate,
    handleDuplicateTemplate,
    handleCloseModal,
    handleEdiOptionChange,
    handleInputChange,
    canSaveTemplate,
  } = useConfigureTemplate(categories);

  if (categories === undefined) return null;

  return (
    <Modal
      opened={openEdit || openDuplicate}
      onClose={handleCloseModal}
      title={
        openEdit ? t('title.configureTemplate') : t('title.duplicateTemplate')
      }
      centered
      size="30%"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 300,
          height: '45vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            overflowY: 'auto',
            flex: 1,
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '0.5rem',
            marginBottom: '2rem',
          }}
        >
          <TextInput
            label={t('common.name')}
            value={name}
            onChange={handleName}
            error={nameError}
            withAsterisk
            data-autofocus
          />

          <Radio.Group
            label={t('common.inputSource')}
            withAsterisk
            onChange={handleSourceChange}
            value={source}
          >
            <Group mt="md">
              {getKeys(SchemaSource).map((src) => {
                const label = src.charAt(0).toUpperCase() + src.slice(1);
                return (
                  <Radio key={src} value={SchemaSource[src]} label={label} />
                );
              })}
            </Group>
          </Radio.Group>

          {source === SchemaSource.Http && (
            <>
              <Radio.Group
                label={t('common.ediOptions')}
                withAsterisk
                onChange={handleEdiOptionChange}
                value={ediOption}
              >
                <Group mt="md">
                  {getKeys(EdiOptions).map((optionKey) => {
                    const label =
                      optionKey.charAt(0).toUpperCase() + optionKey.slice(1);
                    return (
                      <Radio
                        key={optionKey}
                        value={EdiOptions[optionKey]}
                        label={label}
                      />
                    );
                  })}
                </Group>
              </Radio.Group>

              {ediOption && (
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                >
                  {ediOptionInputs[ediOption]?.map((input) => {
                    if (input.internal) return null;

                    const currentValue = ediVariables?.[input.id]?.value ?? '';
                    switch (input.type) {
                      case 'text':
                        return (
                          <TextInput
                            key={input.id}
                            label={input.label}
                            withAsterisk
                            value={currentValue}
                            onChange={(e) =>
                              handleInputChange(
                                input.id,
                                e.target.value,
                                input.schemaVariable
                              )
                            }
                          />
                        );
                      case 'number':
                        return (
                          <NumberInput
                            key={input.id}
                            label={input.label}
                            withAsterisk
                            value={
                              typeof currentValue === 'number'
                                ? currentValue
                                : undefined
                            }
                            onChange={(val) =>
                              handleInputChange(
                                input.id,
                                val ?? 0,
                                input.schemaVariable
                              )
                            }
                          />
                        );
                      default:
                        return null;
                    }
                  })}
                </Box>
              )}
            </>
          )}

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Autocomplete
              label={t('common.category')}
              placeholder={category}
              data={categories.map(({ name }) => name)}
              onChange={handleCategory}
              withAsterisk
            />
            <NumberInput
              label={t('common.priority')}
              value={priority}
              onChange={handlePriority}
              withAsterisk
            />
          </Box>

          <Textarea
            label={t('common.description')}
            value={description}
            onChange={handleDescription}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          {openEdit && (
            <Button onClick={handleEditTemplate} disabled={!canSaveTemplate()}>
              {t('button.update')}
            </Button>
          )}
          {openDuplicate && (
            <Button
              onClick={handleDuplicateTemplate}
              disabled={!canSaveTemplate()}
            >
              {t('button.save')}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
