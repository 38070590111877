import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ZodType } from 'zod';
import { StoreSlice, IBooleanEntry } from '@/store';
import { EntryItemType, EntryType } from '@morph-mapper/types';

export interface BooleanEntryProps {
  id: string;

  // state for entry in-row editing
  backupValue: boolean | undefined;

  // UI state for entry in-row editing
  isConfirm: boolean;
  isEditing: boolean;
}

export type InitBooleanEntryProps = Pick<BooleanEntryProps, 'id'>;

export interface BooleanEntryState extends BooleanEntryProps {
  getId: () => string;
  getType: () => EntryType;
  setType: (type: EntryItemType) => void;
  getName: () => string | undefined;
  getComputed: () => any;
  getValidation: () => ZodType<any> | undefined;

  // functions for entry in-row editing
  setNewValue: (newValue: any) => void;
  setConfirm: (toggle: boolean) => void;
  setEditing: (toggle: boolean) => void;

  cancelDelete: () => void;
  confirmDelete: () => void;
  cancelEdit: () => void;
  confirmEdit: () => void;
  onEdit: () => void;
  onClear: () => void;
  onDelete: () => void;
}

export type BooleanEntryStore = ReturnType<typeof createBooleanEntryStore>;

export const createBooleanEntryStore = (
  global: StoreSlice,
  initProps: InitBooleanEntryProps
) => {
  const DEFAULT_VALUES: Omit<BooleanEntryProps, keyof InitBooleanEntryProps> = {
    backupValue: undefined,
    isConfirm: false,
    isEditing: false,
  };

  return createStore<BooleanEntryState & StoreSlice & IBooleanEntry>()(
    immer((set, get) => ({
      ...global,
      ...global.entries.entry(initProps.id, EntryType.Boolean),
      ...DEFAULT_VALUES,
      ...initProps,

      setNewValue: (newValue) =>
        set((s) => {
          s.setBoolean(newValue);
        }),
      setConfirm: (toggle) => {
        set((s) => {
          s.isConfirm = toggle;
        });
      },
      setEditing: (toggle) => {
        set((s) => {
          s.isEditing = toggle;
        });
      },
      cancelDelete: () => {
        set((s) => {
          s.isConfirm = false;
        });
      },
      confirmDelete: () => {
        set((s) => {
          s.clear();

          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      cancelEdit: () => {
        set((s) => {
          if (s.backupValue !== undefined) {
            s.setBoolean(s.backupValue);
          }

          s.backupValue = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      confirmEdit: () => {
        set((s) => {
          s.backupValue = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      onEdit: () => {
        set((s) => {
          s.backupValue = s.getBoolean();
          s.isEditing = true;
        });
      },
      onClear: () => {
        set((s) => {
          s.clear();

          s.isConfirm = true;
          s.isEditing = false;
        });
      },
      onDelete: () => {
        get().delete();
      },
    }))
  );
};
