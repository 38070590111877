import { GraphEdge, typeConnectorRecord } from '../../types';

export const LOGIC_CATEGORIES = {
  core: 'logicCategories.core',
  transformer: 'logicCategories.transformer',
  logic: 'logicCategories.logic',
  math: 'logicCategories.math',
  request: 'logicCategories.request',
  table: 'logicCategories.table',
  iterator: 'logicCategories.iterator',
  case: 'logicCategories.case',
} as const;

export const LOGIC_CONNECTORS = typeConnectorRecord({
  default: {
    edge: GraphEdge.Add,
  },
});
