import { SchemaVariant } from '@morph-mapper/types';
import { STRUCTURE_BLOCKS_RULESET, STRUCTURE_CATEGORIES } from '../structure';
import { StructureBlockConfig, StructureBlockRecord } from '../types';
import { getKeys, getValues } from '@morph-mapper/utils';

export const useStructureBlocks = (variant: SchemaVariant) => {
  /**
   * @param categories Takes a record of category names and their display names
   * @param blocks Takes a record of logic blocks
   * @returns A record of category names and their respective logic blocks
   */
  const mapBlocksByCategory = (
    categories: Record<string, string>,
    blocks: StructureBlockRecord<any>
  ) => {
    const mappedBlocks: Record<string, StructureBlockConfig<any>[]> = {};

    getKeys(categories).forEach((category) => {
      mappedBlocks[category] = [];
    });

    getValues(blocks).forEach((block) => {
      if (block.category === 'internal') return;
      mappedBlocks[block.category].push(block);
    });

    return mappedBlocks;
  };

  const structureBlocks = STRUCTURE_BLOCKS_RULESET[variant];
  const structureBlocksByCategory = mapBlocksByCategory(
    STRUCTURE_CATEGORIES,
    STRUCTURE_BLOCKS_RULESET[variant]
  );

  return {
    structureBlocks,
    structureBlocksByCategory,
  };
};
